import { AnimatePresence, motion } from "framer-motion";

import { useAppHeaderHeight } from "@/hooks";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { NavLink } from "react-router-dom";

interface Link {
  name: string;
  path: string;
}

interface HeaderProps {
  className?: string;
  links: Link[];
}

export const Header: React.FC<HeaderProps> = ({
  className,
  links,
}) => {
  const { headerRef } = useAppHeaderHeight();

  const scrollTo = (elementId: string) => {
    document.getElementById(elementId.replace('/#/', ''))?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <AnimatePresence>
      <motion.div
        ref={headerRef}
        className={cn(
          "fixed top-0 flex w-full flex-col space-y-2 bg-background-primary pb-2 pt-safe-or-2 h-[75px]",
          className,
        )}
        style={{ zIndex: 101 }}
      >
        <div className="flex flex-1 flex-row items-center px-6">
          <div className="flex flex-row space-x-2">
            {links.map((link, index) => link.path.startsWith('/#/') ? (
              <div
                key={index}
                className={cn(
                  "h-fit cursor-pointer items-center justify-center py-2 text-xs text-text-primary",
                  "border-transparent bg-background-primary",
                )}
                onClick={() => scrollTo(link.path)}
              >
                {link.name}
              </div>
            ) : (
              <div
                key={index}
                className={cn(
                  "h-fit cursor-pointer items-center justify-center py-2 text-xs text-text-primary",
                  "border-transparent bg-background-primary",
                )}
              >
                <NavLink to={'link.path'} className="no-focus">
                  {link.name}
                </NavLink>
              </div>
            ))}
          </div>

          <div className="flex flex-1 flex-row items-center justify-end">
            <div>
              <a href="https://www.ebay.de/deinestadt/delmenhorst" target="_blank" rel="noreferrer" className="w-full inline-block">
                <Button shape="circle" className="text-xs">eBay Deine Stadt</Button>
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
