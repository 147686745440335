import { Button } from "@/components";
import React from "react";
import { NavLink } from "react-router-dom";

export const Footer: React.FC = () => {
  const scrollTo = (elementId: string) => {
    document.getElementById(elementId.replace('/#/', ''))?.scrollIntoView({ behavior: "smooth" });
  }

  const contactInfo = (
    <div className="md:text-right">
      <p>04221 / 99 28 88</p>
      <p>info@dwfg.de</p>
      <p>Lange Straße 128</p>
      <p>27749 Delmenhorst</p>
    </div>
  );

  return (
    <div className="bg-[#1E2128CC] py-[24px] md:py-[56px]">
      <div className="flex flex-col max-w-screen-xl m-auto px-[38px] md:px-0 text-white">
        <div className="flex flex-col md:flex-row justify-between w-full">
          <div>
            <h3 className="text-[24px] md:text-[32px] font-bold">Shopping Delmenhorst</h3>
          </div>
          <div className="space-x-4 py-2">
            <a href="https://www.volksbank-oldenburgland-delmenhorst.de/ihrevolksbank/engagement/regional/freewifidel.html" target="_blank" rel="noreferrer">
              <Button shape="circle" className="bg-transparent border-2">Free Wifi</Button>
            </a>
            <a href="https://www.ebay.de/deinestadt/delmenhorst" target="_blank" rel="noreferrer">
              <Button shape="circle">eBay Deine Stadt</Button>
            </a>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between w-full py-8">
          <div>
            <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-8 text-[16px] font-bold mb-8">
              <p onClick={() => scrollTo('city')}>City & Handel</p>
              <p onClick={() => scrollTo('veranstaltungen')}>Veranstaltungen in der Innenstadt</p>
              <p onClick={() => scrollTo('social')}>Follow us</p>
            </div>
            <div className="lg:hidden">
              {contactInfo}
            </div>
            <div className="pt-8 md:pt-4">
              © 2024 Delmenhorster Wirtschaftsförderungsgesellschaft mbH. All Rights Reserved. • <a href="https://dwfg.de/datenschutz.html" target="_blank" rel="noreferrer">Datenschutz</a> • <a href="https://dwfg.de/impressum.html" target="_blank" rel="noreferrer">Impressum</a>
            </div>
          </div>
          <div className="hidden lg:block">
            {contactInfo}
          </div>
        </div>
      </div>
    </div>
  );
};
