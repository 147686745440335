import { Button } from "@/components";
import { SectionTitle } from "@/components/ui/sectionTitle";
import React from "react";

export const Ebay: React.FC = () => {
  return (
    <div className="mt-[48px] md:mb-[144px] md:mt-[120px] max-w-screen-xl m-auto bg-[#62CD1433] py-[48px] px-[38px] md:px-[70px] md:rounded-[8px]">
      <SectionTitle className="text-left md:justify-start mb-[18px]">eBay Deine Stadt</SectionTitle>
      <p>
        Viele Einzelhändler aus Delmenhorst sind auch online erreichbar. 
      </p>
      <a href="https://www.ebay.de/deinestadt/delmenhorst" target="_blank" rel="noreferrer" className="w-full inline-block">
        <Button shape="circle" className="w-full md:w-fit mt-[36px]">Lokaler Online-Handel</Button>
      </a>
    </div>
  );
};
