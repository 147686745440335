import { SectionTitle } from "@/components/ui/sectionTitle";
import { Veranstaltung } from "@/components/veranstaltung/veranstaltung";
import React from "react";

const events = [
  /*
  {
    name: "Weinfest",
    beschreibung: "Winzer  bieten ihre leckeren Tropfen an und verwandeln die Stadt in eine einladende Weinlandschaft 🍇 ",
    image: "/images/Weinfest.jpg",
    datum: "16. + 17.08.2024",
  },
  */
  {
    name: "Kartoffelfest",
    beschreibung: "Delmenhorst feiert Kartoffelfest am 13. Oktober 2024! 🥔🥳",
    image: "/images/Kartoffelfest.jpg",
    datum: "13.10.2024",
  },
  {
    name: "Laternenumzug",
    beschreibung: "Laterne, Laterne - Sonne, Mond und Sterne 🌟🌙 durch die Innenstadt - mit buntem Rahmenprogramm.",
    image: "/images/Laternenumzug.jpg",
    datum: "25.10.2024",
  },
  {
    name: "Lichterfest",
    beschreibung: "Handwerkskunst, weihnachtliche Dekoration und vieles mehr in besonderer Atmosphäre der Stadtkirche.",
    image: "/images/Lichterfest.jpg",
    datum: "30.11 + 01.12.2024",
  },
  {
    name: "Kinderaktionstag",
    beschreibung: "Spiel und Spaß am 3. Advent: die Markthalle verwandelt sich wieder in ein vorweihnachtliches Kinderparadies!",
    image: "/images/Kinderaktionstag.jpg",
    datum: "15.12.2024",
  },
];

export const Veranstaltungen: React.FC = () => {
  return (
    <>
      <div id="veranstaltungen"></div>
      <div className="bg-[#FAFAFB] py-[120px]">
        <SectionTitle>
          <span className="hidden lg:inline-block">Veranstaltungen in der Innenstadt</span>
          <span className="lg:hidden">Veranstaltungen</span>
        </SectionTitle>
        <div className="flex flex-row overflow-scroll space-x-8 mt-[24px] max-w-screen-xl m-auto px-12 items-center">
          {events.slice(0, 4).map((event, index) => (
            <div key={`event-${index}`} className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <Veranstaltung name={event.name} beschreibung={event.beschreibung} datum={event.datum} image={event.image} />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-[36px]">
          <a href="https://www.dwfg.de/veranstaltungen.html" target="_blank" rel="noreferrer">
            <button className="border border-[#171A1F] text-[#171A1F] py-2 px-4 rounded-full">Alle Veranstaltungen</button>
          </a>
        </div>
      </div>
    </>
  );
};
