import { NavLink } from "react-router-dom";

import { cn } from "@/lib/utils";

import { AnimatedClickable } from "../animatedClickable";
import { Button } from "../ui/button";

interface Link {
  name: string;
  path: string;
}

interface AppHeaderDesktopProps {
  links: Link[];
  className?: string;
}

export const AppHeaderDesktop: React.FC<AppHeaderDesktopProps> = ({
  links,
  className,
}) => {
  const scrollTo = (elementId: string) => {
    document.getElementById(elementId.replace('/#/', ''))?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div
      className={cn(
        "sticky top-0 flex w-full flex-col bg-background-primary drop-shadow-xl",
        className,
      )}
      style={{ zIndex: 101 }}
    >
      <div className="flex w-full max-w-screen-xl m-auto flex-row items-center justify-between space-x-4 bg-background-primary px-4 py-4">
        <div className="flex flex-row items-center space-x-8">
          <NavLink to="/" className="no-focus cursor-pointer">
            <Logo />
          </NavLink>

          <div className="flex flex-row space-x-4">
            {links.map((link, index) => link.path.startsWith('/#/') ? (
              <AnimatedClickable
                key={index}
                className={cn(
                  "h-fit cursor-pointer items-center justify-center rounded-full px-4 py-2 text-sm text-text-primary hover:bg-background-tertiary",
                  "border-transparent bg-background-primary",
                )}
                animate={{ opacity: 0.5 }}
                onClick={() => scrollTo(link.path)}
              >
                {link.name}
              </AnimatedClickable>
            ) : (
              <AnimatedClickable
                key={index}
                className={cn(
                  "h-fit cursor-pointer items-center justify-center rounded-full px-4 py-2 text-sm text-text-primary hover:bg-background-tertiary",
                  "border-transparent bg-background-primary",
                )}
                animate={{ opacity: 0.5 }}
              >
                <NavLink to={link.path} className="no-focus">
                  {link.name}
                </NavLink>
              </AnimatedClickable>
            ))}
          </div>
        </div>

        <div className="flex flex-1 flex-row items-center justify-end space-x-4">
            <div>
              <a href="https://www.volksbank-oldenburgland-delmenhorst.de/ihrevolksbank/engagement/regional/freewifidel.html" target="_blank" rel="noreferrer">
                Free Wifi
              </a>
            </div>
            <div>
              <a href="https://www.ebay.de/deinestadt/delmenhorst" target="_blank" rel="noreferrer" className="w-full inline-block">
                <Button shape="circle">eBay Deine Stadt</Button>
              </a>
            </div>
        </div>
      </div>
    </div>
  );
};

const Logo: React.FC = () => (
  <div className="flex flex-row items-center space-x-3">
    <span className="text-md font-semibold text-text-primary">Shopping Delmenhorst</span>
  </div>
);

