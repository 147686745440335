import { SectionTitle } from "@/components/ui/sectionTitle";
import React from "react";

export const FollowUs: React.FC = () => {
  return (
    <div className="bg-[#FAFAFB] py-[24px] md:py-[48px]" id="social">
      <div className="flex flex-row mt-[24px] max-w-screen-xl m-auto">
        <div className="flex flex-col-reverse md:flex-row px-[38px] md:px-0">
          <div className="w-full md:w-1/2">
            <SectionTitle className="mb-[28px] md:mb-[42px] justify-start">Follow us</SectionTitle>
            <div className="max-w-[465px] text-[14px] md:text-[18px]">
              <div className="mt-[12px] mb-[20px]">
                <a href="https://www.instagram.com/delmenhorstmittendrin/" target="_blank" rel="noreferrer" className="text-[#37750C] hover:underline">
                  <strong className="text-[16px] md:text-[18px]">@delmenhorstmittendrin</strong>
                </a>
                <p>
                  Stadtwandel: Revitalisierung der Delmenhorster Innenstadt 💚, Leerstände füllen, Zukunft gestalten und die Gemeinschaft stärken🌏🏬🏡
                </p>  
              </div>
              <div className="mt-[12px] mb-[20px]">
                <a href="https://www.instagram.com/delmenhorst_entdecken/" target="_blank" rel="noreferrer" className="text-[#37750C] hover:underline">
                  <strong className="text-[16px] md:text-[18px]">@delmenhorst_entdecken</strong>
                </a>
                <p>
                Die offiziellen Social Media-Accounts der dwfg! Marketing & Tourismus / Citymanagement... #Delmenhorst ist unsere Leidenschaft ❤️ <br />
                👇🏼 Guck‘ doch mal rein
                </p>
                <a href="https://www.instagram.com/delmenhorst_entdecken/" target="_blank" rel="noreferrer">
                  <div className="flex flex-col h-[44px] w-[44px] rounded-full bg-[#171A1F] justify-center items-center mt-4">
                    <img src="/images/icon-instagram.png" alt="Instagram" className="w-[24px] h-[24px] inline-block" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <img src="/images/Weinfest.jpg" alt="City & Handel" className="w-full object-cover rounded-[8px] mb-[28px]" />
          </div>
        </div>
      </div>
    </div>
  );
};
