import React from "react";
import { Intro } from "./sections/01_Intro";
import { Slogan } from "./sections/02_Slogan";
import { Veranstaltungen } from "./sections/03_Veranstaltungen";
import { CityHandel } from "./sections/04_CityHandel";
import { Ebay } from "./sections/05_ebay";
import { FollowUs } from "./sections/06_FollowUs";
import { Footer } from "./sections/07_Footer";

import "./Home.css";

export const Home: React.FC = () => {
  return (
    <div className="h-screen">
      <Intro />
      <Slogan />
      <Veranstaltungen />
      <CityHandel />
      <Ebay />
      <FollowUs />
      <Footer />
    </div>
  );
};
