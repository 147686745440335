import React from "react";
import { cn } from "@/lib/utils";

interface VeranstaltungProps {
  name: string;
  beschreibung: string;
  image: string;
  datum: string;
  className?: string;
}

export const Veranstaltung: React.FC<VeranstaltungProps> = ({
  name,
  beschreibung,
  image,
  datum,
  className,
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex h-[220px] w-[267px] justify-center" style={{ zIndex: 10 }}>
        <img src={image} alt={name} className="h-[220px] w-[220px] object-cover rounded-full" />
      </div>
      <div
        className={cn(
          "flex flex-col h-full w-[267px] bg-white mt-[-110px] pt-[132px] px-4 pb-4 rounded-[8px]",
          className,
        )}
      >
        <p className="font-bold">
          {name}
        </p>
        <p className="text-xs pt-2 pb-4 h-[64px]">
          {beschreibung}
        </p>
        <span className="w-fit text-[#37750C] bg-[#F1FDE9] rounded-full text-[14px] leading-[36px] px-[12px]">
          {datum}
        </span>
      </div>
    </div>
  );
};
