import React from "react";

export const Slogan: React.FC = () => {
  return (
    <div className="py-[75px] md:py-[120px] text-center p-5">
      <p>
        <strong className="text-sm md:text-md">Wirtschaft fördern, Tourismus stärken, City erleben und Stadt entwickeln:</strong>
      </p>
      <p className="text-sm md:text-md">Das sind die vier großen Säulen der Delmenhorster Wirtschaftsförderungsgesellschaft mbH, kurz: dwfg.</p>
      <p className="text-sm md:text-md mt-[16px] max-w-screen-md m-auto">Seit 2015 vereint die Gesellschaft die Wirkungsfelder Wirtschaftsförderung, Stadtmarketing und Citymanagement unter einem Dach.</p>
    </div>
  );
};
