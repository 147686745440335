import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

import { AppHeader, AppHeaderDesktop } from "@/components";
import { useAppHeaderHeight } from "@/hooks";
import { UIContext } from "@/context";
import { cn } from "@/lib/utils";

// Define the links (Desktop)
const LINKS = [
  { name: "City & Handel", path: "/#/city" },
  { name: "Veranstaltungen", path: "/#/veranstaltungen" },
  { name: "Follow Us", path: "/#/social" },
];

export const LayoutApp: React.FC = () => {
  const { appHeaderHeight } = useAppHeaderHeight();
  const { isDeviceMobile }: any = useContext(UIContext);

  return (
    <div className="relative flex h-dvh w-screen flex-col bg-background-primary">
      {/* Outlet and content should scroll within this container */}
      <div
        className="flex flex-1 flex-col overflow-y-auto"
        style={{ paddingTop: appHeaderHeight }}
      >
        {/* App Header (conditionally rendered & hidden via tailwind classes depending on device) */}
        <AppHeader
          links={LINKS.slice(0, 2)}
          className={cn(!isDeviceMobile && "md:hidden")}
        />

        {/** App Header for Desktop (hidden via tailwind classes for mobile devicess) */}
        <AppHeaderDesktop
          links={LINKS}
          className={cn(isDeviceMobile ? "hidden" : "max-md:hidden")}
        />

        <Outlet key={"outlet"} />

      </div>
    </div>
  );
};
